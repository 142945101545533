<template>
  <v-container class="px-0">
    <app-input-row label="debtor" dense>
      <disabled-input-box :value="debtor.name" />
    </app-input-row>

    <app-input-row dense label="location">
      <disabled-input-box>
        <div class="semi-bold">{{ location.name }}</div>
        <template v-if="location.address.streetAndHouse">
          {{ location.address.streetAndHouse }},&#32;
        </template>
        {{ location.address.city }}
        {{ location.address.zip }}
      </disabled-input-box>
    </app-input-row>

    <app-input-row dense label="permanentDebts.repetition">
      <disabled-input-box>{{ formData.contract.period | formatPeriod }}</disabled-input-box>
    </app-input-row>

    <app-input-row label="permanentDebts.firstExecution" dense>
      <disabled-input-box :value="firstExecutionDate" />
    </app-input-row>

    <app-input-row label="permanentDebts.end" dense>
      <disabled-input-box>
        {{ endDate ? endDate : $t('permanentDebts.ongoing') }}
      </disabled-input-box>
    </app-input-row>

    <app-input-row
      dense
      label="permanentDebts.grossAmount"
      :sub-label="`${formData.amount.vat}% ${$t('permanentDebts.vat')}`"
    >
      <disabled-input-box>
        {{ formData.amount.grossAmount | formatCurrency }}
      </disabled-input-box>
    </app-input-row>

    <app-input-row dense label="permanentDebts.bankDetails">
      <disabled-input-box>
        <app-labeled-data
          class="text-left"
          wrap
          no-gutters
          label="accountOwner"
          :value="formData.sepa.accountOwner || debtor.sepa.accountOwner"
        />
        <app-labeled-data
          class="text-left"
          wrap
          no-gutters
          label="iban"
          :value="formData.sepa.iban || debtor.sepa.iban"
        />
      </disabled-input-box>
    </app-input-row>
  </v-container>
</template>

<script>
import { formatIsoToLocal } from '@/helper/filter/formatDate';
import DisabledInputBox from '@/shared/components/DisabledInputBox';

export default {
  name: 'PermanentDebtSummary',

  components: {
    DisabledInputBox
  },

  props: {
    debtor: {
      type: Object,
      required: true
    },
    location: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },

  computed: {
    firstExecutionDate() {
      return formatIsoToLocal(this.formData.contract.startDate);
    },
    endDate() {
      return formatIsoToLocal(this.formData.contract.endDate);
    }
  }
};
</script>
