<template>
  <v-container class="px-0">
    <app-input-row label="permanentDebts.netAmount" dense>
      <app-number-field
        v-model="amountData.netAmount"
        name="permanentDebts.netAmount"
        rules="required"
        prepend-inner-icon="mdi-currency-eur"
        placeholder="0,00"
        align-right
      />
    </app-input-row>

    <app-input-row label="permanentDebts.vat" dense>
      <app-select
        v-model="amountData.vat"
        :items="vatSelectItems"
        :label="$t('permanentDebts.vat')"
        required
        solo
      />
    </app-input-row>

    <app-input-row label="permanentDebts.grossAmount" dense>
      <app-number-field
        v-model="amountData.grossAmount"
        name="permanentDebts.grossAmount"
        rules="required"
        prepend-inner-icon="mdi-currency-eur"
        placeholder="0,00"
        align-right
      />
    </app-input-row>
  </v-container>
</template>

<script>
import VAT_RATES from '@/statics/vatRates';

export default {
  name: 'AmountForm',

  props: {
    formData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      amountData: {
        ...this.formData.amount
      }
    };
  },

  computed: {
    vatSelectItems() {
      return VAT_RATES.map((option) => ({
        label: `${option} %`,
        value: option
      }));
    }
  },

  watch: {
    'amountData.netAmount'() {
      this.calculateGrossAmount();
    },
    'amountData.vat'() {
      this.calculateGrossAmount();
    },
    amountData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          amount: value
        });
      },
      deep: true
    }
  },

  methods: {
    calculateGrossAmount() {
      this.amountData.grossAmount = this.amountData.netAmount * (1 + this.amountData.vat / 100);
    }
  }
};
</script>
