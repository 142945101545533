<template>
  <app-select
    filter
    :items="states"
    label="status"
    label-icon="icon-message"
    hide-details
    dense
    solo
    v-bind="$attrs"
    v-on="$listeners"
  />
</template>

<script>
import formatReadStatus from '@/helper/filter/formatReadStatus';
import { READ_STATUS } from '@/modules/User/statics/readStatus';

export default {
  name: 'StatusSelect',

  computed: {
    states() {
      return READ_STATUS.map((option) => ({
        label: formatReadStatus(option),
        value: option
      }));
    }
  }
};
</script>
