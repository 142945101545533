<template>
  <app-icon-btn class="read-status__button" :loading="loading" v-bind="$attrs" v-on="$listeners">
    <div :class="{ 'read-status__dot': true, 'read-status__dot--unread': isUnread }"></div>
  </app-icon-btn>
</template>

<script>
export default {
  name: 'ReadStatusButton',

  props: {
    isUnread: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
$dotHeight: 0.5625rem;
$dotHeightMobile: 0.75rem;
$buttonPadding: 0.5rem;
$buttonMargin: #{-$buttonPadding};

.read-status__button.read-status__button {
  line-height: inherit;
  width: auto;
  height: auto;
  padding: $buttonPadding;
  margin: $buttonMargin;
  position: relative;
  top: -0.0625rem;
}
.read-status__dot {
  height: $dotHeight;
  width: $dotHeight;
  background-color: #f0f0f0;
  border-radius: 50%;
  box-shadow: inset -0.5px 0 4px 0 rgba(0, 0, 0, 0.1);

  &.read-status__dot--unread {
    background-color: var(--c-primary);
    box-shadow: inset -0.5px 0 4px 0 rgba(255, 255, 255, 0.2), 0 0 2px 0 rgba(0, 75, 179, 0.2);
  }
}

.v-application.mobile {
  .read-status__button {
    top: -0.09375rem;
  }

  .read-status__dot {
    height: $dotHeightMobile;
    width: $dotHeightMobile;
  }
}
</style>
