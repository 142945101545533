export default {
  computed: {
    dateSelection: {
      get() {
        return this.getFilterQueryParam(this.queryParam.OVERLAY__DATE);
      },
      set(value) {
        this.setFilterQueryParam(this.queryParam.OVERLAY__DATE, value);
      }
    },
    statusSelection: {
      get() {
        return this.getFilterQueryParam(this.queryParam.OVERLAY__STATUS);
      },
      set(value) {
        this.setFilterQueryParam(this.queryParam.OVERLAY__STATUS, value);
      }
    },

    hasActiveFilters() {
      return [...this.dateSelection, ...this.statusSelection].length > 0;
    }
  },

  methods: {
    resetAllFilter(closeOverlay = false) {
      this.setQueryParams(
        {
          ...this.$route.query,
          [this.queryParam.OVERLAY__STATUS]: undefined,
          [this.queryParam.OVERLAY__DATE]: undefined,
          ...(closeOverlay ? { [this.queryParam.OVERLAY__PAGE]: undefined } : {})
        },
        closeOverlay
      );
    }
  }
};
