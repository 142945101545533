<template>
  <v-list class="notifications__list" tag="ul">
    <template v-for="(entry, index) in notifications">
      <v-divider v-if="index > 0" :key="'divider_' + entry.id" />

      <v-hover v-slot="{ hover }" :key="entry.id">
        <v-list-item
          v-long-press="onLongPress"
          tag="li"
          :class="{
            'notifications__content--inset': true,
            'notification-item': true,
            'notification-item--unread': isUnread(entry),
            'notification-item--hover': hover
          }"
          v-on="
            !showSelection || !isUnread(entry)
              ? null
              : {
                  click: () => onSelect(entry)
                }
          "
        >
          <div v-if="showSelection">
            <app-checkbox
              :value="selectedEntries"
              :item-value="entry.id"
              v-bind="
                !isUnread(entry)
                  ? { disabled: true, class: 'notification-item__checkbox--hidden' }
                  : null
              "
            />
          </div>
          <div>
            <read-status-button
              :loading="isLoading(entry)"
              :is-unread="isUnread(entry)"
              class="align-self-start"
              @click.stop="() => onToggleRead(entry)"
            />
          </div>
          <div class="notification-item__content" @click="showSelection ? null : redirect(entry)">
            <span
              :class="{
                'notification-item__title': true,
                'semi-bold': true,
                'notification-item__title--unread': isUnread(entry)
              }"
            >
              {{ entry.title }}
            </span>
            <span class="subtitle-1 grey--text">
              {{ entry.message }}
            </span>
          </div>
          <div class="notification-item__action caption grey--text">
            <app-link-btn
              v-if="hover && !isMobile"
              :loading="isLoading(entry)"
              :title="toggleActionTitle(entry)"
              variant="muted"
              @click.stop="() => onToggleRead(entry)"
            />
            <template v-else>{{ entry.created | formatDate }}</template>
          </div>
        </v-list-item>
      </v-hover>
    </template>
    <v-divider />
  </v-list>
</template>

<script>
const DEBTOR_NOTIFICATION_TYPES = [
  'limit-reason-debtor-person-was-not-identified',
  'limit-reason-debtor-person-was-found-on-blacklist',
  'limit-reason-debtor-person-was-died',
  'limit-reason-debtor-person-has-a-negative-solvency',
  'limit-reason-debtor-company-was-terminated',
  'limit-reason-debtor-company-was-not-identified',
  'limit-reason-debtor-company-was-found-on-blacklist',
  'limit-reason-debtor-company-has-a-negative-solvency',
  'limit-ko-was-set'
];

import ReadStatus from '@/modules/User/statics/readStatus';
import ReadStatusButton from '@/modules/User/components/ReadStatusButton';
import formatReadStatus from '@/helper/filter/formatReadStatus';

export default {
  name: 'NotificationsList',

  props: {
    checkUnreadFn: {
      type: Function,
      required: true
    },
    showSelection: {
      type: Boolean,
      default: false
    },
    loadingEntries: {
      type: Array,
      required: true
    },
    notifications: {
      type: Array,
      required: true
    },
    selectedEntries: {
      type: Array,
      required: true
    }
  },

  components: {
    ReadStatusButton
  },

  methods: {
    isUnread(entry) {
      return this.checkUnreadFn(entry);
    },

    onLongPress() {
      this.$emit('long-press');
    },

    onToggleRead(entry) {
      this.$emit('toggle-read', entry);
    },

    onSelect(entry) {
      this.$emit('selected', entry.id);
    },

    async redirect(entry) {
      if (this.showSelection) {
        return;
      }

      if (this.isUnread(entry)) {
        this.onToggleRead(entry);
      }

      if (entry.debtor && (DEBTOR_NOTIFICATION_TYPES.includes(entry.type) || !entry.voucher)) {
        await this.$router.push(this.getDebtorRoute(entry.debtor));
      }

      if (entry.voucher) {
        await this.$router.push(this.getVoucherRoute(entry.voucher));
      }

      this.menu = false;
    },

    isLoading(entry) {
      return this.loadingEntries.includes(entry.id);
    },

    toggleActionTitle(entry) {
      return this.isUnread(entry)
        ? formatReadStatus(ReadStatus.READ)
        : formatReadStatus(ReadStatus.NEW);
    }
  }
};
</script>

<style scoped lang="scss">
.notifications__list {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
}

.notification-item {
  padding-top: 24px;
  padding-bottom: 24px;
  display: flex;
  align-items: flex-start;

  > * + * {
    margin-left: 1rem;
  }
}

.notification-item__action {
  flex: 0 0 70px;
  min-width: fit-content;
  margin-top: 3px;
  text-align: end;
}

.notification-item__checkbox--hidden {
  cursor: pointer;

  ::v-deep .v-input {
    opacity: 0;
  }
}

.notification-item__content {
  cursor: pointer;
  flex-grow: 1;
}

.notification-item__title {
  display: block;
  color: var(--c-grey);

  &.notification-item__title--unread {
    color: var(--c-text);
  }
}

.notification-item--hover {
  background-color: #f9f9f9;
}
</style>
