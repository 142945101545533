<template>
  <app-dialog
    v-bind="$attrs"
    :disabled="disableUpload"
    :loading="loading"
    :primary-action-text="showActions ? 'upload.upload' : null"
    :secondary-action-text="showActions ? 'navigation.cancel' : null"
    :value="value"
    v-on="$listeners"
    @submit="startUpload"
  >
    <template v-if="!showActions && !showTemplates">
      <p class="mt-n8">{{ $t('upload.uploadType') }}</p>
      <div class="py-4 mb-4 mt-1 grey-background">
        <app-radio-group
          :value="selectedUploadType.value"
          :items="uploadTypeItems"
          name="vouchers.voucherType"
          required
          justify="space-between"
          @input="selectedUploadType = uploadTypeItems.find((item) => item.value === $event)"
        >
          <template #radio>
            <v-radio
              v-for="item in uploadTypeItems"
              :key="item.value"
              :value="item.value"
              class="px-4"
            >
              <template #label>
                {{ item.title }}
                <app-tooltip v-if="item.info" :text="item.info">
                  <icon-info class="grey--text mx-2" />
                </app-tooltip>
              </template>
            </v-radio>
          </template>
        </app-radio-group>
      </div>
    </template>

    <voucher-templates v-if="showTemplates" @cancel="showTemplates = false" />

    <div v-else>
      <component
        :is="selectedUploadType.component"
        ref="voucherDropzone"
        :loading.sync="loading"
        :is-ready.sync="uploadIsReady"
        :additional-form-data="additionalFormData"
        @finished="finishUpload"
      />
    </div>

    <voucher-upload-additions v-if="!showActions" @showTemplates="showTemplates = true" />
  </app-dialog>
</template>

<script>
import AppDropzone from '@/shared/components/AppDropzone';
import EncashmentInvoiceUpload from '@/modules/Upload/components/EncashmentInvoiceUpload';
import InvoiceUpload from '@/modules/Upload/components/InvoiceUpload';
import VoucherTemplates from '@/modules/Upload/components/VoucherTemplates';
import VoucherType from '@/statics/voucherType';
import VoucherUploadAdditions from '@/shared/components/VoucherUploadAdditions';

export default {
  name: 'UploadDialog',

  props: {
    additionalFormData: {
      type: Object,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AppDropzone,
    VoucherTemplates,
    VoucherUploadAdditions
  },

  data: () => ({
    showTemplates: false,
    uploadIsReady: false,
    disableUpload: false,
    selectedUploadType: {},
    loading: false
  }),

  watch: {
    value() {
      if (this.value) {
        return;
      }

      setTimeout(() => this.reset(), 200);
    }
  },

  computed: {
    uploadTypeItems() {
      return [
        {
          value: VoucherType.INVOICE,
          title: this.$t('upload.standardInvoice'),
          component: InvoiceUpload
        },
        {
          value: VoucherType.ENCASHMENT_INVOICE,
          title: this.$t('upload.encashmentDirect'),
          info: 'upload.encashmentInvoiceInfo',
          component: EncashmentInvoiceUpload
        }
      ];
    },
    showActions() {
      return this.uploadIsReady || this.loading || this.submitted;
    }
  },

  methods: {
    startUpload() {
      this.loading = true;
      this.$refs.voucherDropzone.startUpload();
    },

    finishUpload(redirectVoucherId) {
      this.$emit('success', redirectVoucherId);
    },

    reset() {
      this.showTemplates = false;
      this.selectedUploadType = this.uploadTypeItems[0];
      this.loading = false;
      this.$refs.voucherDropzone?.reset();
    }
  },

  created() {
    this.selectedUploadType = this.uploadTypeItems[0];
  }
};
</script>
