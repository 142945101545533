<template>
  <v-container class="px-0">
    <app-input-row dense label="accountOwner">
      <disabled-input-box v-if="sepaRecorded" :value="debtor.sepa.accountOwner" />
      <app-text-field
        v-else
        v-model="sepaData.accountOwner"
        required
        name="accountOwner"
        placeholder="firstAndLastName"
      />
    </app-input-row>

    <app-input-row dense label="iban">
      <disabled-input-box v-if="sepaRecorded" :value="debtor.sepa.iban" />
      <app-text-field
        v-else
        v-model="sepaData.iban"
        placeholder="DEXX XX XXXXXXXX"
        name="iban"
        rules="required|iban"
      />
    </app-input-row>

    <app-input-row dense class="mt-6">
      <app-checkbox
        v-if="!sepaRecorded"
        v-model="sepaData.confirmed"
        label="permanentDebts.confirmSepa"
        hide-details
        required
      />
      <a
        v-if="!sepaRecorded"
        class="text-left d-block pdf-download"
        :href="product.documents.sepa.template.path"
        target="_blank"
      >
        ({{ product.documents.sepa.template.title }})
      </a>
    </app-input-row>
  </v-container>
</template>

<script>
import DisabledInputBox from '@/shared/components/DisabledInputBox';

export default {
  name: 'SepaForm',

  components: { DisabledInputBox },

  props: {
    debtor: {
      type: Object,
      required: true
    },
    formData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      sepaData: {
        ...this.formData.sepa
      }
    };
  },

  computed: {
    sepaRecorded() {
      return this.debtor.sepa;
    }
  },

  watch: {
    sepaData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          sepa: value
        });
      },
      deep: true
    }
  }
};
</script>

<style scoped>
>>> a.pdf-download {
  margin-left: 32px;
  font-size: 14px;
}
</style>
