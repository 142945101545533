<template>
  <ValidationObserver ref="form" tag="form">
    <div v-if="!dropzoneEmpty" class="d-flex flex-column align-center justify-center mb-12">
      <div class="icon__wrapper">
        <icon-vouchers width="36px" height="36px" />
      </div>
      <h3 class="dropzone-custom-title semi-bold mt-4">
        {{ $t('upload.encashmentDirect') }}
      </h3>
    </div>
    <app-dropzone
      v-show="voucherInQueue === undefined"
      ref="encashmentDropzone"
      v-model="vouchers"
      :additional-form-data="formData"
      :has-empty-queue.sync="dropzoneEmpty"
      :max-files="1"
      :start-upload="isUploading"
      :url="url"
      type="upload.encashmentInvoice"
      @error="showError"
      @input="error = false"
      @success="finishUpload"
    />
    <template v-if="!dropzoneEmpty">
      <app-alert :value="error" />
      <app-input-row label="voucher">
        <app-file-input
          :value="voucherInQueue"
          :name="$t('voucher')"
          placeholder="choosePDF"
          align-right
          prepend-inner-icon
          required
          rules="ext:pdf"
          @input="setFileFromFileInput"
        />
      </app-input-row>

      <app-input-row label="vouchers.dateOfFirstDunning">
        <app-date-picker
          v-model="dateOfFirstDunningLetter"
          name="vouchers.dateOfFirstDunning"
          placeholder="dateHint"
          prepend-inner-icon
          align-right
          required
          :max="today"
        />
      </app-input-row>

      <app-input-row label="debtors.debtorType">
        <app-select
          v-model="debtorType"
          :items="debtorTypeItems"
          label="debtors.debtorType"
          required
        />
      </app-input-row>

      <app-input-row label="exposureType">
        <app-select
          v-model="exposureType"
          :items="exposureTypeItems"
          label="exposureType"
          required
        />
        <template #append>
          <app-checkbox
            v-if="exposureType && exposureType !== defaultExposureType"
            v-model="setExposureTypeAsDefault"
            class="mt-2"
            label="vouchers.exposureType.setExposureTypeAsDefault"
          />
        </template>
      </app-input-row>
    </template>
  </ValidationObserver>
</template>
<script>
import ExposureTypeMixin from '@/mixins/ExposureTypeMixin';
import VoucherUploadMixin from '@/mixins/VoucherUploadMixin';
import { BASE_URL } from '@/plugins/axios';
import { MILLISECONDS_PER_DAY, getMilliSecondsStartToday } from '@/statics/timePeriods';
import { formatDateToTimestamp, formatTimestampToDate } from '@/helper/filter/formatDate';

export default {
  name: 'EncashmentInvoiceUpload',

  mixins: [VoucherUploadMixin, ExposureTypeMixin],

  data: () => ({
    dateOfFirstDunningLetter: '',
    error: false
  }),

  computed: {
    url() {
      return `${BASE_URL}/voucher/upload/invoice-encashment`;
    },
    today() {
      return formatTimestampToDate((getMilliSecondsStartToday() - MILLISECONDS_PER_DAY) / 1000);
    },
    formData() {
      return {
        ...this.additionalFormData,
        debtorType: this.debtorType,
        exposureType: this.exposureType,
        firstDunningDate: formatDateToTimestamp(this.dateOfFirstDunningLetter)
      };
    },
    voucherInQueue() {
      return this.vouchers.find((voucher) => voucher.status === 'queued');
    }
  },

  methods: {
    async startUpload() {
      this.error = false;

      if (!(await this.$refs.form.validate())) {
        return this.stopUpload();
      }

      this.isUploading = true;
    },

    async finishUpload(voucherId) {
      this.$emit('finished', voucherId);

      await this.submitDefaultExposureType();
      this.defaultExposureType = this.exposureType;

      setTimeout(() => this.reset(), 200);
    },

    reset() {
      this.stopUpload();

      this.vouchers = [];
      this.exposureType = this.defaultExposureType;
      this.debtorType = '';
      this.dateOfFirstDunningLetter = '';
      this.setExposureTypeAsDefault = false;
      this.error = false;
    },

    showError() {
      this.error = true;
      this.stopUpload();
    },

    setFileFromFileInput(file) {
      if (!file) {
        return this.reset();
      }

      this.$refs.encashmentDropzone.manuallyRemoveFiles();
      this.$refs.encashmentDropzone.manuallyAddFile(file);
    }
  },

  created() {
    this.getDefaultExposureType();
  }
};
</script>
