<template>
  <app-alert color="primary" icon="$iconInfo" text="debtors.requiresDebtorAssignment" />
</template>

<script>
export default {
  name: 'DebtorAssignmentInformation',

  created() {
    this.$emit('update:saveFunc', () => this.$emit('submit'));
  }
};
</script>
