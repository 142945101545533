<template>
  <app-dialog
    v-model="open"
    :title="views[currentViewIndex].title"
    :primary-action-text="views[currentViewIndex].primaryAction"
    :secondary-action-text="views[currentViewIndex].secondaryAction"
    :loading="loading"
    @submit="save"
    @close="onClose"
  >
    <template v-if="views[currentViewIndex].secondaryAction" #secondaryAction>
      <app-btn block outlined @click="cancel">
        {{ $t(views[currentViewIndex].secondaryAction) }}
      </app-btn>
    </template>

    <component
      :is="views[currentViewIndex].component"
      :value="value"
      :save-func.sync="save"
      :data="newDebtorData"
      :error="error"
      @submit="views[currentViewIndex].submitFunction($event)"
    />
  </app-dialog>
</template>

<script>
/* eslint-disable sonarjs/no-duplicate-string */
import ConfirmCreateDebtorBilling from '@/modules/Debtors/components/CreateDebtor/ConfirmCreateDebtorBilling';
import ConfirmCreateDebtorFactoring from '@/modules/Debtors/components/CreateDebtor/ConfirmCreateDebtorFactoring';
import ConfirmCreateLocation from '@/modules/Debtors/components/CreateDebtor/ConfirmCreateLocation';
import CreateDebtorForm from '@/modules/Debtors/components/CreateDebtor/CreateDebtorForm';
import DebtorAssignmentInformation from '@/modules/Debtors/components/CreateDebtor/DebtorAssignmentInformation';
import DebtorType from '@/modules/Debtors/statics/debtorType';
import DoubletInformation from '@/modules/Debtors/components/CreateDebtor/DoubletInformation';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import showSnackbar from '@/helper/showSnackbar';
import { NAMESPACE } from '@/modules/Debtors/store';
import { ActionLogType } from '@/statics/actionLogType';
import { mapActions } from 'vuex';

export default {
  name: 'CreateDebtorDialog',

  mixins: [ShowDialogMixin],

  components: {
    CreateDebtorForm,
    ConfirmCreateLocation,
    ConfirmCreateDebtorFactoring,
    ConfirmCreateDebtorBilling,
    DoubletInformation,
    DebtorAssignmentInformation
  },

  data() {
    return {
      newDebtorData: null,
      save: () => {},
      currentViewIndex: 0
    };
  },

  computed: {
    views() {
      return [
        {
          // currentViewIndex 0
          component: 'create-debtor-form',
          title: this.isFactoring ? 'debtors.addDebtorAndCheckLimit' : 'debtors.createDebtor',
          primaryAction: this.isFactoring ? 'debtors.checkLimit' : 'debtors.checkAddress',
          secondaryAction: 'navigation.cancel',
          submitFunction: this.submitRequestIdentCheck
        },
        // currentViewIndex 1
        this.isFactoring
          ? {
              component: 'confirm-create-debtor-factoring',
              title: 'debtors.checkLimitResult',
              primaryAction: 'debtors.createDebtor',
              secondaryAction: 'debtors.dontCreateDebtor',
              submitFunction: this.submitCreateDebtor,
              cancel: true,
              logCreationAborted: true
            }
          : {
              component: 'confirm-create-debtor-billing',
              title: 'debtors.createDebtor',
              primaryAction: 'navigation.yesCreate',
              secondaryAction: 'navigation.noCancel',
              submitFunction: this.submitCreateDebtor,
              cancel: true,
              logCreationAborted: true
            },
        {
          // currentViewIndex 2
          component: 'confirm-create-location',
          title: 'debtors.debtorFound',
          primaryAction: 'navigation.yesAdd',
          secondaryAction: 'navigation.noCancel',
          submitFunction: this.submitCreateDebtor,
          cancel: true,
          logCreationAborted: true
        },
        {
          // currentViewIndex 3
          component: 'doublet-information',
          title: 'debtors.debtorFound',
          primaryAction: 'navigation.toDebtor',
          secondaryAction: 'navigation.goBack',
          submitFunction: this.redirectToLocation
        },
        {
          // currentViewIndex 4
          component: 'debtor-assignment-information',
          title: 'debtors.debtorCreated',
          primaryAction: 'navigation.ok',
          submitFunction: this.hideAndReloadList,
          closeFunction: this.hideAndReloadList
        }
      ];
    }
  },

  watch: {
    value() {
      if (!this.value && this.currentViewIndex > 0) {
        setTimeout(() => {
          this.currentViewIndex = 0;
        }, 1000);
      }
    }
  },

  methods: {
    ...mapActions(['createActionLogEntry']),
    ...mapActions(NAMESPACE, ['requestIdentCheck', 'createDebtor', 'fetchFilterDebtorNames']),

    async submitRequestIdentCheck(payload) {
      // submit new debtor data, get information if debtor or location already exists
      this.loading = true;
      const { error, response } = await this.requestIdentCheck(payload);
      this.loading = false;
      if (error) {
        this.error = true;
        return;
      }
      this.newDebtorData = response;
      this.setFollowUpView();
    },

    setFollowUpView() {
      if (
        (this.newDebtorData.debtor && !this.newDebtorData.isNewLocation) ||
        (this.newDebtorData.debtor && this.newDebtorData.debtor.type === DebtorType.CUSTOMER)
      ) {
        // business debtor and location or customer debtor already exists, no need to create
        this.currentViewIndex = 3;
        return;
      }
      if (
        this.newDebtorData.debtor &&
        this.newDebtorData.debtor.type === DebtorType.BUSINESS &&
        this.newDebtorData.isNewLocation
      ) {
        // business data exists, but location needs to be created
        this.currentViewIndex = 2;
        return;
      }

      // show limit check and create debtor
      this.currentViewIndex++;
    },

    async submitCreateDebtor() {
      this.loading = true;
      const { debtorId, locationId, error } = await this.createDebtor();

      await this.fetchFilterDebtorNames();

      this.loading = false;
      if (error) {
        return;
      }

      if (this.newDebtorData.requiresDebtorAssignment) {
        // debtor created, show info alert for manual assignment
        this.currentViewIndex = 4;
        return;
      }

      this.$emit('input', false);

      this.$router.push(
        this.currentViewIndex === 1
          ? // created debtor, push to debtor
            this.getDebtorRoute({ id: debtorId, name: this.newDebtorData.limitAddress.name })
          : // created location only, push to location
            this.getLocationRoute({
              id: locationId,
              debtorId: debtorId,
              name: this.newDebtorData.requestData.name
            })
      );

      showSnackbar({ text: 'debtors.debtorCreated', color: 'success', icon: '$iconCheck' });
      this.$eventHub.$emit('reload-debtors');
    },

    redirectToLocation() {
      this.$router.push(
        this.getLocationRoute({
          id: this.newDebtorData.location.id,
          debtorId: this.newDebtorData.debtor.id,
          name: this.newDebtorData.location.name
        })
      );

      this.$emit('input', false);
    },

    cancel() {
      this.error = false;

      if (this.views[this.currentViewIndex].logCreationAborted) {
        this.logCreationAborted();
      }

      if (this.currentViewIndex > 0 && !this.views[this.currentViewIndex].cancel) {
        this.currentViewIndex = 0;
        return;
      }

      this.$emit('input', false);
    },

    onClose() {
      if (this.views[this.currentViewIndex].logCreationAborted) {
        this.logCreationAborted();
      }

      this.views[this.currentViewIndex].closeFunction?.();
    },

    hideAndReloadList() {
      this.$emit('input', false);
      this.$eventHub.$emit('reload-debtors');
    },

    async logCreationAborted() {
      await this.createActionLogEntry({
        actionType: ActionLogType.CREDITOR_DEBTOR_CREATION_ABORTED,
        payload: this.newDebtorData.requestData,
        creditorIdentifier: this.$auth.user().creditorId,
        creditorSapNumber: this.$auth.user().sapNumber
      });
    }
  }
};
</script>
