export default {
  data: () => ({
    confirmedFactoringRules: false
  }),

  computed: {
    requiresConfirmationOfFactoringRules() {
      return (
        this.$auth.user().requiresConfirmationOfFactoringRules && !this.confirmedFactoringRules
      );
    },
    additionalFormData() {
      if (!this.confirmedFactoringRules) {
        return {};
      }

      return {
        goldenRulesWereApproved: true
      };
    }
  },

  methods: {
    async updateUserDataAfterConfirmation() {
      if (this.confirmedFactoringRules) {
        await this.$auth.fetch({});
        this.confirmedFactoringRules = false;
      }
    },

    resetConfirmation() {
      this.confirmedFactoringRules = false;
    }
  }
};
