<template>
  <div :class="{ 'd-flex': true, 'justify-end': !isMobile, 'text-left': isMobile }">
    <div class="pa-4 grey-background">
      <slot>{{ value }}</slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisabledInputBox',

  props: {
    value: {
      type: String,
      default: ''
    }
  }
};
</script>
