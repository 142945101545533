<template>
  <v-container class="pa-0">
    <v-row>
      <v-col>
        {{ $t('debtors.createDebtorConfirm') }}
      </v-col>
    </v-row>

    <v-row class="mx-6" justify="center">
      <v-col class="shrink">
        <icon-clients />
      </v-col>
      <v-col class="grow">
        <div>{{ data.limitAddress.name }}</div>
        <div class="d-inline-flex">
          <p>
            <template v-if="data.limitAddress.streetAndHouse">
              {{ data.limitAddress.streetAndHouse }},
            </template>
            {{ data.limitAddress.zip }} {{ data.limitAddress.city }}
          </p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ConfirmCreateDebtorBilling',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  created() {
    this.$emit('update:saveFunc', () => this.$emit('submit'));
  }
};
</script>
