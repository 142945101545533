<template>
  <v-container class="px-0">
    <template v-if="debtors.length === 0">
      <p>{{ $t('permanentDebts.noDebtors') }}</p>
      <app-link-btn
        class="mt-4"
        title="debtors.createFirst"
        variant="muted"
        @click="openCreateDebtorDialog"
      />
    </template>

    <template v-else>
      <app-autocomplete
        id="create-permanent-debt__debtor"
        v-model="selectedDebtor"
        :items="debtorSelectItems"
        :label="$t('debtor')"
        required
      />

      <app-radio-group
        v-if="selectedDebtor"
        v-model="selectedLocation"
        :row="false"
        :items="locationRadioItems"
        name="location"
        required
      >
        <template #radio>
          <v-radio
            v-for="(item, index) in locationRadioItems"
            :key="index"
            :value="item.value"
            class="my-2"
          >
            <template #label>
              <div class="semi-bold">{{ item.name }}</div>
              <template v-if="item.address">
                <template v-if="item.address.streetAndHouse">
                  {{ item.address.streetAndHouse }},&#32;
                </template>
                {{ item.address.zip }} {{ item.address.city }}
              </template>
            </template>
          </v-radio>
        </template>
      </app-radio-group>
    </template>
  </v-container>
</template>

<script>
export default {
  name: 'DebtorForm',

  props: {
    formData: {
      type: Object,
      required: true
    },
    debtor: {
      type: Object,
      default: null
    },
    debtors: {
      type: Array,
      required: true
    },
    location: {
      type: Object,
      default: null
    }
  },

  computed: {
    debtorSelectItems() {
      return this.debtors.map((debtor) => ({
        label: debtor.name,
        value: debtor
      }));
    },
    locationRadioItems() {
      return this.selectedDebtor.locations.map((location) => ({
        name: location.name,
        address: location.address,
        value: location
      }));
    },
    selectedDebtor: {
      get() {
        return this.debtor;
      },
      set(value) {
        this.$emit('update:debtor', value);

        if (value.locations.length === 1) {
          this.selectedLocation = value.locations[0];
          return;
        }

        this.selectedLocation = null;
      }
    },
    selectedLocation: {
      get() {
        return this.location;
      },
      set(value) {
        this.$emit('update:location', value);
      }
    }
  }
};
</script>

<style scoped>
>>> .v-input--selection-controls.v-input--radio-group {
  margin-top: 1rem;
}

.v-radio {
  border: 1px solid var(--c-grey);
  border-radius: 2px;
  padding: 0.5rem 1rem;
}

>>> .v-input--selection-controls .v-radio > .v-label {
  flex-direction: column;
  align-items: flex-start;
}
</style>
