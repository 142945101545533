<template>
  <div>
    <app-dialog
      :value="open"
      title="permanentDebts.createPermanentDebt"
      :primary-action-text="primaryActionText"
      :loading="loading && !!debtor"
      :disabled="loading || debtors.length === 0"
      @submit="onNext"
      @close="onClose"
    >
      <app-alert :value="!!error" class="mb-4" />

      <p v-html="$t('permanentDebts.createInfo')"></p>

      <v-progress-linear color="secondary" height="8" class="mt-6" :value="progress" />
      <span class="subtitle-1 semi-bold mt-2">{{ $t(steps[currentStepIndex].text) }}</span>

      <v-row v-if="loading" class="my-4" justify="center">
        <v-col cols="auto">
          <app-spinner />
        </v-col>
      </v-row>

      <ValidationObserver v-else ref="form" v-slot="{ failed }" class="mt-4">
        <component
          :is="steps[currentStepIndex].component"
          :form-data.sync="formData"
          :debtor.sync="debtor"
          :location.sync="location"
          v-bind="steps[currentStepIndex].data"
        />
        <error-alert :value="failed" />
      </ValidationObserver>

      <template #secondaryAction>
        <app-btn block outlined :title="secondaryActionText" @click="onCancel" />
      </template>
    </app-dialog>

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toPermanentDebt"
      secondary-action-text="navigation.backToOverview"
      title="permanentDebts.createdPermanentDebt"
      @submit="pushToPermanentDebt"
      @close="closeAfterSubmit"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { NAMESPACE } from '@/modules/PermanentDebts/store';
import createPermanentDebtFactory from '@/helper/createPermanentDebtFactory';
import { formatDateToTimestamp } from '@/helper/filter/formatDate';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import ErrorAlert from '@/shared/CreateCreditor/ErrorAlert';
import DebtorForm from '@/modules/PermanentDebts/components/Details/CreatePermanentDebt/DebtorForm';
import ContractForm from '@/modules/PermanentDebts/components/Details/CreatePermanentDebt/ContractForm';
import AmountForm from '@/modules/PermanentDebts/components/Details/CreatePermanentDebt/AmountForm';
import ServiceForm from '@/modules/PermanentDebts/components/Details/CreatePermanentDebt/ServiceForm';
import SepaForm from '@/modules/PermanentDebts/components/Details/CreatePermanentDebt/SepaForm';
import PermanentDebtSummary from '@/modules/PermanentDebts/components/Details/CreatePermanentDebt/PermanentDebtSummary';

export default {
  name: 'CreatePermanentDebtDialog',

  mixins: [ShowDialogMixin],

  components: {
    DebtorForm,
    ContractForm,
    AmountForm,
    ErrorAlert,
    ServiceForm,
    SepaForm,
    PermanentDebtSummary
  },

  data: () => ({
    formData: {},
    currentStepIndex: 0,
    debtors: [],
    debtor: null,
    location: null,
    createdPermanentDebtId: ''
  }),

  computed: {
    steps() {
      return [
        {
          component: 'debtor-form',
          text: 'permanentDebts.progress.debtor',
          data: { debtors: this.debtors }
        },
        {
          component: 'contract-form',
          text: 'permanentDebts.progress.contract'
        },
        {
          component: 'service-form',
          text: 'permanentDebts.progress.service'
        },
        {
          component: 'amount-form',
          text: 'permanentDebts.progress.amount'
        },
        {
          component: 'sepa-form',
          text: 'permanentDebts.progress.sepa'
        },
        {
          component: 'permanent-debt-summary',
          text: 'permanentDebts.progress.summary'
        }
      ];
    },
    progress() {
      return (100 / this.steps.length) * (this.currentStepIndex + 1);
    },
    primaryActionText() {
      return this.currentStepIndex + 1 === this.steps.length
        ? 'navigation.confirm'
        : 'navigation.continue';
    },
    secondaryActionText() {
      return this.currentStepIndex ? 'navigation.goBack' : 'navigation.cancel';
    }
  },

  watch: {
    open: {
      async handler() {
        if (!this.open) {
          return;
        }

        this.loading = true;
        this.formData = createPermanentDebtFactory();
        const { debtors, error } = await this.fetchPermanentDebtDebtors();
        this.loading = false;

        if (error) {
          this.error = error;
          return;
        }

        this.debtors = debtors;

        if (
          this.getQueryParam(this.queryParam.CREATE_PERMANENT_DEBT) === true ||
          !this.$route.params.id
        ) {
          return;
        }

        this.getDebtorFromQuery();
      },
      immediate: true
    },
    location() {
      if (!this.location && this.$refs.form) {
        this.$refs.form?.reset();
      }
    }
  },

  methods: {
    ...mapActions(NAMESPACE, ['createPermanentDebt', 'fetchPermanentDebtDebtors']),

    getDebtorFromQuery() {
      let { debtorId, locationId } = this.getDebtorIds();
      this.debtor = this.debtors.find((debtor) => debtor.id === debtorId);

      if (!this.debtor) {
        return;
      }
      this.location = this.debtor.locations.find((location) => location.id === locationId);

      if (!this.location) {
        this.debtor = null;
        return;
      }

      this.currentStepIndex = 1;
    },

    getDebtorIds() {
      if (typeof this.getQueryParam(this.queryParam.CREATE_PERMANENT_DEBT) === 'string') {
        const [debtorId, locationId] = this.getQueryParam(
          this.queryParam.CREATE_PERMANENT_DEBT
        ).split(',');

        return { debtorId, locationId };
      }
      const [debtorId, locationId] = this.$route.params.id.split(',');

      return { debtorId, locationId };
    },

    async onNext() {
      if (!(await this.$refs.form.validate())) {
        return;
      }

      if (this.currentStepIndex + 1 < this.steps.length) {
        this.currentStepIndex++;
        return;
      }

      this.submit();
    },

    async submit() {
      this.loading = true;
      const formData = this.getFormData();

      const { response, error } = await this.createPermanentDebt({
        locationIdentifier: this.location.id,
        formData
      });

      this.loading = false;
      if (error) {
        this.error = error;
        return;
      }

      this.createdPermanentDebtId = response;
      this.submitted = true;
    },

    getFormData() {
      let formData = new FormData();

      const formDataAsJson = {
        contract: {
          period: this.formData.contract.period,
          start: formatDateToTimestamp(this.formData.contract.startDate),
          end: this.formData.contract.endDate
            ? formatDateToTimestamp(this.formData.contract.endDate)
            : '',
          performanceDateIndex: this.formData.contract.dayOfPerformance,
          amount: this.formData.amount.grossAmount,
          amountNet: this.formData.amount.netAmount,
          vat: this.formData.amount.vat
        },
        service: {
          item: this.formData.service.subject,
          customNumber: this.formData.service.customNumber,
          comment: this.formData.service.description,
          ...(this.formData.service.attachment
            ? {
                file: this.formData.service.attachment,
                fileName: this.formData.service.attachment.name
              }
            : null)
        },
        sepa: {
          accountOwner: this.formData.sepa.accountOwner,
          iban: this.formData.sepa.iban
        }
      };

      Object.keys(formDataAsJson).forEach((key) => {
        Object.keys(formDataAsJson[key]).forEach((nestedKey) => {
          formData.append(`${key}[${nestedKey}]`, formDataAsJson[key][nestedKey]);
        });
      });

      return formData;
    },

    onCancel() {
      if (this.currentStepIndex > 0) {
        this.currentStepIndex--;
        return;
      }
      this.onClose();
    },

    onClose() {
      this.$emit('input', false);
      this.resetForm();
    },

    closeAfterSubmit() {
      this.submitted = false;
      this.onClose();
      this.$eventHub.$emit('permanent-debt-created');
    },

    pushToPermanentDebt() {
      this.submitted = false;
      this.onClose();

      this.$router.push(
        this.getPermanentDebtRoute({
          id: this.createdPermanentDebtId,
          location: this.location.name
        })
      );
    },

    resetForm() {
      setTimeout(() => {
        this.currentStepIndex = 0;
        this.debtor = null;
        this.location = null;
        this.error = false;
        this.formData = createPermanentDebtFactory();

        if (this.$refs.form) {
          this.$refs.form?.reset();
        }
      }, 200);
    }
  }
};
</script>
