<template>
  <div>
    <confirm-factoring-rules-dialog
      v-if="requiresConfirmationOfFactoringRules"
      v-model="open"
      @confirm="confirmedFactoringRules = true"
    />

    <upload-dialog
      v-else
      v-model="open"
      :additional-form-data="additionalFormData"
      @success="finishUpload"
      @close="closeDialog"
    />

    <app-dialog
      v-model="openConfirmation"
      primary-action-text="navigation.toVouchers"
      @submit="hideAfterRedirect"
      @close="hideAfterSuccess"
    >
      <template #title>
        <h3 class="semi-bold">
          <v-icon style="font-size: 5rem" color="secondary">mdi-check-bold</v-icon>
        </h3>
      </template>

      <p>{{ $t('upload.uploadCompleted') }}</p>

      <template #secondaryAction>
        <app-btn block outlined @click="uploadMoreVouchers">
          {{ $t('upload.selectNextVouchers') }}
        </app-btn>
      </template>
    </app-dialog>
  </div>
</template>

<script>
import ConfirmFactoringRulesDialog from '@/shared/components/ConfirmFactoringRulesDialog';
import ConfirmFactoringRulesMixin from '@/mixins/ConfirmFactoringRulesMixin';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import UploadDialog from '@/modules/Upload/components/UploadDialog';
import { NAMESPACE as NAMESPACE_UNPROCESSED } from '@/modules/Unprocessed/store';
import { NAMESPACE as NAMESPACE_VOUCHERS } from '@/modules/Vouchers/store';
import { mapActions } from 'vuex';

export default {
  name: 'VoucherUploadDialog',

  components: { ConfirmFactoringRulesDialog, UploadDialog },

  mixins: [ShowDialogMixin, ConfirmFactoringRulesMixin],

  data() {
    return {
      redirectVoucherId: ''
    };
  },

  watch: {
    value(value) {
      if (!value) {
        return;
      }

      this.confirmedFactoringRules = false;
    }
  },

  methods: {
    ...mapActions(NAMESPACE_UNPROCESSED, ['fetchUnprocessedList']),
    ...mapActions(NAMESPACE_VOUCHERS, ['fetchUnprocessedVouchersCount']),

    finishUpload(redirectVoucherId) {
      this.redirectVoucherId = redirectVoucherId;
      this.submitted = true;
      this.updateUserDataAfterConfirmation();
    },

    resetDialog() {
      this.redirectVoucherId = '';
      this.resetConfirmation();
    },

    closeDialog() {
      setTimeout(() => this.resetDialog(), 200);
      this.updateStore();
    },

    hideAfterSuccess() {
      this.submitted = false;
      this.updateStore();
      setTimeout(() => this.resetDialog(), 200);
      this.$emit('input', false);
    },

    updateStore() {
      if (!this.redirectVoucherId) {
        return;
      }

      if (this.$route.name === this.routeName.UNPROCESSED) {
        this.fetchUnprocessedList();
      }

      if (this.$route.name === this.routeName.VOUCHERS) {
        this.fetchUnprocessedVouchersCount();
      }
    },

    async hideAfterRedirect() {
      this.submitted = false;
      this.$emit('input', false);

      this.$route.name === this.routeName.UNPROCESSED
        ? await this.fetchUnprocessedList()
        : this.fetchUnprocessedList();

      this.$router.push({
        name: this.routeName.UNPROCESSED,
        params: { id: this.redirectVoucherId }
      });

      setTimeout(() => this.resetDialog(), 200);
    },

    uploadMoreVouchers() {
      this.submitted = false;
    }
  }
};
</script>
