var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"notifications__list",attrs:{"tag":"ul"}},[_vm._l((_vm.notifications),function(entry,index){return [(index > 0)?_c('v-divider',{key:'divider_' + entry.id}):_vm._e(),_c('v-hover',{key:entry.id,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',_vm._g({directives:[{name:"long-press",rawName:"v-long-press",value:(_vm.onLongPress),expression:"onLongPress"}],class:{
          'notifications__content--inset': true,
          'notification-item': true,
          'notification-item--unread': _vm.isUnread(entry),
          'notification-item--hover': hover
        },attrs:{"tag":"li"}},
          !_vm.showSelection || !_vm.isUnread(entry)
            ? null
            : {
                click: function () { return _vm.onSelect(entry); }
              }
        ),[(_vm.showSelection)?_c('div',[_c('app-checkbox',_vm._b({attrs:{"value":_vm.selectedEntries,"item-value":entry.id}},'app-checkbox',
              !_vm.isUnread(entry)
                ? { disabled: true, class: 'notification-item__checkbox--hidden' }
                : null
            ,false))],1):_vm._e(),_c('div',[_c('read-status-button',{staticClass:"align-self-start",attrs:{"loading":_vm.isLoading(entry),"is-unread":_vm.isUnread(entry)},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onToggleRead(entry); }).apply(null, arguments)}}})],1),_c('div',{staticClass:"notification-item__content",on:{"click":function($event){_vm.showSelection ? null : _vm.redirect(entry)}}},[_c('span',{class:{
              'notification-item__title': true,
              'semi-bold': true,
              'notification-item__title--unread': _vm.isUnread(entry)
            }},[_vm._v(" "+_vm._s(entry.title)+" ")]),_c('span',{staticClass:"subtitle-1 grey--text"},[_vm._v(" "+_vm._s(entry.message)+" ")])]),_c('div',{staticClass:"notification-item__action caption grey--text"},[(hover && !_vm.isMobile)?_c('app-link-btn',{attrs:{"loading":_vm.isLoading(entry),"title":_vm.toggleActionTitle(entry),"variant":"muted"},on:{"click":function($event){$event.stopPropagation();return (function () { return _vm.onToggleRead(entry); }).apply(null, arguments)}}}):[_vm._v(_vm._s(_vm._f("formatDate")(entry.created)))]],2)])]}}],null,true)})]}),_c('v-divider')],2)}
var staticRenderFns = []

export { render, staticRenderFns }