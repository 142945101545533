import TimePeriod from '@/modules/PermanentDebts/statics/timePeriod';
import { VAT_RATES } from '@/statics/vatRates';

export default () => ({
  contract: {
    period: TimePeriod.MONTHLY,
    startDate: '',
    endDate: '',
    dayOfPerformance: ''
  },
  service: {
    subject: '',
    customNumber: '',
    description: '',
    attachment: null
  },
  amount: {
    vat: VAT_RATES[0],
    grossAmount: '',
    netAmount: ''
  },
  sepa: {
    accountOwner: '',
    iban: '',
    confirmed: false
  }
});
