<template>
  <v-container class="pa-0">
    <v-row class="mx-6" justify="center">
      <v-col class="shrink">
        <icon-clients />
      </v-col>
      <v-col class="grow">
        <div>{{ data.limitAddress.name }}</div>
        <div class="d-inline-flex">
          <p>
            <template v-if="data.limitAddress.streetAndHouse">
              {{ data.limitAddress.streetAndHouse }},
            </template>
            {{ data.limitAddress.zip }} {{ data.limitAddress.city }}
          </p>
        </div>
      </v-col>
    </v-row>

    <v-row class="mt-6">
      <v-col class="d-flex justify-center">
        <img :src="limitImageSrc" :alt="$t('debtors.limit')" />
      </v-col>
      <v-col>
        <div class="caption">
          {{
            hasMediumLimit ? $t('debtors.temporaryLimitAmountsTo') : $t('debtors.limitAmountsTo')
          }}
        </div>
        <div class="text-h2 pt-2 pb-5 d-flex align-center">
          {{ data.newDebtorLimit | formatCurrency }}
          <app-tooltip v-if="!hasMaxLimit && formattedReasonCodes.length > 0">
            <template #text>
              <div class="caption pa-2">
                {{ $t('debtors.limitAffectedBy') }}:
                <ul>
                  <li v-for="(code, index) in formattedReasonCodes" :key="index" class="py-2">
                    {{ code }}
                  </li>
                </ul>
              </div>
            </template>
            <icon-info class="ml-4" />
          </app-tooltip>
        </div>
        <div class="caption text--muted">
          {{ hasMediumLimit ? $t('debtors.temporaryResultInfo') : $t('debtors.limitResultInfo') }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { LimitColor } from '@/modules/Debtors/statics/limitColor';
import { formatLimitReasonCodes } from '@/helper/formatLimitReasonCodes';

export default {
  name: 'ConfirmCreateDebtorFactoring',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  computed: {
    hasMaxLimit() {
      return this.data.newDebtorLimitColor === LimitColor.GREEN;
    },
    hasMediumLimit() {
      return this.data.newDebtorLimitColor === LimitColor.YELLOW;
    },
    limitImageSrc() {
      if (this.hasMaxLimit) {
        return require('@/modules/Debtors/assets/traffic-light-green.svg');
      }
      if (this.hasMediumLimit) {
        return require('@/modules/Debtors/assets/traffic-light-yellow.svg');
      }
      return require('@/modules/Debtors/assets/traffic-light-red.svg');
    },
    formattedReasonCodes() {
      return formatLimitReasonCodes(this.data.reasonCodes, this.data.requestData.type);
    }
  },

  created() {
    this.$emit('update:saveFunc', () => this.$emit('submit'));
  }
};
</script>
