<template>
  <filter-wrapper :has-active-filters="hasActiveFilters" @reset="resetAllFilter">
    <v-row dense>
      <v-col cols="12" sm="6">
        <status-select v-model="statusSelection" />
      </v-col>
      <v-col cols="12" sm="6">
        <date-select v-model="dateSelection" />
      </v-col>
    </v-row>

    <template>
      <v-row v-if="hasActiveFilters" dense>
        <v-col v-if="hasActiveFilters" cols="12" class="pt-3">
          <active-filter-chip
            v-for="(selection, index) in statusSelection"
            :key="selection"
            v-model="statusSelection"
            color="text"
            :index="index"
            icon="icon-message"
          >
            {{ selection | formatReadStatus }}
          </active-filter-chip>
          <active-filter-chip
            v-for="(selection, index) in dateSelection"
            :key="'date_' + selection"
            v-model="dateSelection"
            color="text"
            :index="index"
            icon="icon-calendar"
          >
            {{ $t('date') }}:
            <template v-if="!selection.start">
              {{ $t('to') }} {{ selection.end | formatDate }}
            </template>
            <template v-else-if="!selection.end">
              {{ $t('from') }} {{ selection.start | formatDate }}
            </template>
            <template v-else>
              {{ selection.start | formatDate }}
              <template v-if="selection.start !== selection.end">
                - {{ selection.end | formatDate }}
              </template>
            </template>
          </active-filter-chip>

          <app-text-btn
            v-if="hasActiveFilters"
            color="text"
            class="caption"
            title="resetFilter"
            @click="resetAllFilter"
          />
        </v-col>
      </v-row>
    </template>
  </filter-wrapper>
</template>

<script>
import ActiveFilterChip from '@/shared/components/ActiveFilterChip';
import DateSelect from '@/shared/DateSelect';
import FilterWrapper from '@/shared/components/FilterWrapper';
import OverlayFilterMixin from '@/mixins/OverlayFilterMixin';
import StatusSelect from '@/modules/User/components/Notifications/StatusSelect';

export default {
  name: 'NotificationsFilter',

  mixins: [OverlayFilterMixin],

  components: {
    ActiveFilterChip,
    DateSelect,
    FilterWrapper,
    StatusSelect
  }
};
</script>

<style scoped>
.notifications__filter {
  padding: 24px;
}
</style>
