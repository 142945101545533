<template>
  <div>
    <app-alert v-if="error" class="mb-4">
      {{ isFactoring ? $t('debtors.checkDebtorError') : $t('debtors.createDebtorError') }}
    </app-alert>

    <v-row no-gutters>
      <v-col cols="12">
        <v-radio-group v-model="form" row :mandatory="true">
          <v-radio
            v-for="debtorForm in debtorForms"
            :key="debtorForm.label"
            color="primary"
            :label="debtorForm.label"
            :value="debtorForm.value"
          />
        </v-radio-group>
      </v-col>
    </v-row>

    <component :is="form" :save-func.sync="save" @submit="$emit('submit', $event)" />
  </div>
</template>

<script>
import NewBusinessDebtorForm from '@/modules/Debtors/components/CreateDebtor/NewBusinessDebtorForm';
import NewCustomerDebtorForm from '@/modules/Debtors/components/CreateDebtor/NewCustomerDebtorForm';

export default {
  name: 'CreateDebtorForm',

  components: { NewBusinessDebtorForm, NewCustomerDebtorForm },

  props: {
    value: {
      type: Boolean,
      default: false
    },
    saveFunc: {
      type: Function,
      default: () => {}
    },
    error: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      form: null,
      debtorForms: [
        {
          label: this.$i18n.t('company'),
          value: 'new-business-debtor-form'
        },
        {
          label: this.$i18n.t('privatePerson'),
          value: 'new-customer-debtor-form'
        }
      ]
    };
  },

  computed: {
    save: {
      get() {
        return this.saveFunc;
      },
      set(saveFunction) {
        this.$emit('update:saveFunc', saveFunction);
      }
    }
  },

  watch: {
    value() {
      if (!this.value) {
        this.form = null;
      }
    }
  }
};
</script>

<style scoped>
>>> .v-input__slot {
  border-radius: 2px;
}
>>> .v-input--selection-controls {
  margin: 0;
}
>>> .v-input--selection-controls .v-input__control {
  flex-grow: 1;
}

>>> .v-input--radio-group__input {
  justify-content: space-around;
}
</style>
