import AppDropzone from '@/shared/components/AppDropzone';

export default {
  components: {
    AppDropzone
  },

  props: {
    additionalFormData: {
      type: Object,
      default: null
    },
    disableUpload: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    notEmpty: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    vouchers: [],
    showTemplates: false,
    showActions: false,
    dropzoneEmpty: false,
    isUploading: false
  }),

  watch: {
    dropzoneEmpty: {
      handler() {
        this.$emit('update:is-ready', !this.dropzoneEmpty);
      },
      immediate: true
    }
  },

  methods: {
    startUpload() {
      this.isUploading = true;
    },

    stopUpload() {
      this.isUploading = false;
      this.$emit('update:loading', false);
    },

    finishUpload(voucherId) {
      this.$emit('finished', voucherId);
      setTimeout(() => this.reset(), 200);
    },

    reset() {
      this.stopUpload();
      this.vouchers = [];
    }
  }
};
