<template>
  <div v-if="!$auth.check(role.ADMIN)">
    <notifications-dialog v-model="showNotificationsDialog" />
    <voucher-upload-dialog v-model="showVoucherUploadDialog" />
    <create-debtor-dialog v-model="showCreateDebtorDialog" />
    <create-permanent-debt-dialog v-model="showCreatePermanentDebtDialog" />
  </div>
</template>

<script>
import CreateDebtorDialog from '@/modules/Debtors/components/CreateDebtor/CreateDebtorDialog';
import CreatePermanentDebtDialog from '@/modules/PermanentDebts/components/Details/CreatePermanentDebt/CreatePermanentDebtDialog';
import NotificationsDialog from '@/modules/User/components/Notifications/NotificationsDialog';
import VoucherUploadDialog from '@/modules/Upload/VoucherUploadDialog';

export default {
  name: 'GlobalDialogs',

  components: {
    CreateDebtorDialog,
    CreatePermanentDebtDialog,
    NotificationsDialog,
    VoucherUploadDialog
  },

  computed: {
    showNotificationsDialog: {
      get() {
        return (
          this.getQueryParam(this.queryParam.SHOW_NOTIFICATIONS) &&
          this.permissions.notifications.show
        );
      },
      set(value) {
        this.setQueryParam(this.queryParam.SHOW_NOTIFICATIONS, value);
      }
    },
    showVoucherUploadDialog: {
      get() {
        return (
          this.getQueryParam(this.queryParam.UPLOAD_VOUCHER) && this.permissions.vouchers.upload
        );
      },
      set(value) {
        this.setQueryParam(this.queryParam.UPLOAD_VOUCHER, value);
      }
    },
    showCreateDebtorDialog: {
      get() {
        return (
          this.getQueryParam(this.queryParam.CREATE_DEBTOR) && this.permissions.debtors.createDebtor
        );
      },
      set(value) {
        this.setQueryParam(this.queryParam.CREATE_DEBTOR, value);
      }
    },
    showCreatePermanentDebtDialog: {
      get() {
        return (
          (this.getQueryParam(this.queryParam.CREATE_PERMANENT_DEBT) &&
            this.permissions.permanentDebts.create) ||
          (this.getQueryParam(this.queryParam.ACTION) === this.queryParam.CREATE_PERMANENT_DEBT &&
            this.permissions.debtorDetails.createPermanentDebt)
        );
      },
      set(value) {
        if (this.getQueryParam(this.queryParam.ACTION)) {
          this.setQueryParam(this.queryParam.ACTION, undefined);
          return;
        }

        this.setQueryParam(this.queryParam.CREATE_PERMANENT_DEBT, value);
      }
    }
  }
};
</script>
