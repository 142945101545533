import i18n from '@/helper/i18n';
/**
 * @param {String[]} codeList
 * @param {'customer' | 'business'} type
 */
export const formatLimitReasonCodes = (codeList, type) =>
  codeList
    .filter((code) =>
      Object.keys(i18n.t(`debtors.limitReasonCodes.${type}`)).includes(code.toString())
    )
    .map((code) =>
      i18n.te(`debtors.limitReasonCodes.${type}.${code}`)
        ? i18n.t(`debtors.limitReasonCodes.${type}.${code}`)
        : '-'
    );
