<template>
  <app-dialog v-model="showDialog" title="notifications.title">
    <div class="notifications__container">
      <div v-if="showSelection">
        <div class="notifications__content--inset d-flex justify-space-between">
          <app-text-btn
            color="button-link"
            class="notifications__btn text--underlined"
            :loading="isLoadingReadAll"
            @click="onSelectAll"
          >
            {{ $t('notifications.selectAll') }}
          </app-text-btn>
          <app-text-btn
            color="grey"
            class="notifications__btn"
            :loading="isLoadingReadAll"
            @click="onCancelSelect"
          >
            {{ $t('navigation.cancel') }}
          </app-text-btn>
        </div>
        <div class="text-center text-h5 mb-12">
          <span class="primary--text">
            {{ selectedEntriesIds.length }}
          </span>
          {{ $t('notifications.selected') }}
        </div>
      </div>

      <template v-else-if="listInitiallyLoaded">
        <div class="notifications__content--inset">
          <notifications-filter />
        </div>
        <div class="d-flex justify-center py-2">
          <app-text-btn
            :class="{ notifications__btn: true, 'text--underlined': !allRead }"
            :disabled="allRead"
            :loading="isLoadingReadAll"
            :title="allRead ? 'notifications.youReadAll' : 'readAll'"
            color="button-link"
            @click.stop="onReadAll"
          />
        </div>
      </template>

      <v-progress-linear
        v-bind="
          isLoading
            ? {
                color: 'primary',
                indeterminate: true
              }
            : { color: 'transparent' }
        "
      />
      <v-divider />

      <p v-if="notifications.length === 0" class="notifications__content--inset mt-8">
        {{
          isLoading
            ? $t('loadingData')
            : notificationsEmpty
            ? $t('notifications.noData')
            : $t('noMatchingResults')
        }}
      </p>
      <notifications-list
        v-else
        :check-unread-fn="isUnread"
        :show-selection="showSelection"
        :loading-entries="loadingEntriesIds"
        :notifications="notifications"
        :selected-entries="selectedEntriesIds"
        @long-press="toggleShowSelection"
        @selected="toggleSelect"
        @toggle-read="toggleRead"
      />
    </div>

    <template #append>
      <app-text-btn
        v-if="showSelection"
        :disabled="selectedEntriesIds.length === 0"
        :loading="isLoadingReadMultiple"
        class="notifications__btn text--underlined mx-auto"
        color="button-link"
        title="notifications.readSelected"
        @click="onReadMultiple"
      />

      <div v-else class="notifications__pagination">
        <v-pagination v-model="page" :length="numPages" total-visible="6" circle />
      </div>
    </template>
  </app-dialog>
</template>

<script>
import NotificationsFilter from '@/modules/User/components/Notifications/NotificationsFilter';
import NotificationsList from '@/modules/User/components/Notifications/NotificationsList';
import OverlayFilterMixin from '@/mixins/OverlayFilterMixin';
import ReadStatus from '@/modules/User/statics/readStatus';
import ShowDialogMixin from '@/mixins/ShowDialogMixin';
import showSnackbar from '@/helper/showSnackbar';
import { NAMESPACE } from '@/modules/User/store';
import { QueryParam } from '@/statics/queryParam';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'NotificationsDialog',

  mixins: [ShowDialogMixin, OverlayFilterMixin],

  components: {
    NotificationsList,
    NotificationsFilter
  },

  data: () => ({
    isLoading: false,
    isLoadingReadAll: false,
    isLoadingReadMultiple: false,
    itemsPerPage: 20,
    loadingEntriesIds: [],
    listInitiallyLoaded: false,
    selectedEntriesIds: [],
    showSelection: false
  }),

  computed: {
    ...mapState(NAMESPACE, [
      'notifications',
      'notificationsCount',
      'notificationsEmpty',
      'unreadNotificationsCount'
    ]),
    allRead() {
      return this.unreadNotificationsCount === 0;
    },
    numPages() {
      return Math.ceil(this.notificationsCount / this.itemsPerPage);
    },
    showDialog: {
      get() {
        return this.open;
      },
      set(value) {
        // set query param only if no routing action is pending
        // to prevent conflict with other navigations while dialog is opened
        if (
          !this.$router.history.pending ||
          this.$router.history.pending.fullPath === this.$route.fullPath
        ) {
          this.setQueryParam(this.queryParam.SHOW_NOTIFICATIONS, value);
        }
      }
    },
    page: {
      get() {
        return !isNaN(this.getQueryParam(QueryParam.OVERLAY__PAGE))
          ? Number(this.getQueryParam(QueryParam.OVERLAY__PAGE))
          : 1;
      },
      set(value) {
        this.setQueryParam(QueryParam.OVERLAY__PAGE, value ?? 1, true);
      }
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        this.scrollTo(-100);
        this.resetAllFilter(true);
      }
    },
    page() {
      if (!this.showDialog) {
        return;
      }

      this.scrollTo('.notifications__container');
    },
    '$route.query': {
      deep: true,
      async handler(newQuery, oldQuery) {
        if (!this.showDialog) {
          return;
        }

        if (
          newQuery[QueryParam.OVERLAY__PAGE] === oldQuery?.[QueryParam.OVERLAY__PAGE] &&
          newQuery[QueryParam.OVERLAY__PAGE] !== 1
        ) {
          this.page = 1;
          return;
        }
        await this.loadNotifications();
        this.listInitiallyLoaded = true;
      },
      immediate: true
    }
  },

  methods: {
    ...mapActions(NAMESPACE, [
      'fetchNotifications',
      'readAllNotifications',
      'readMultipleNotifications',
      'readNotification',
      'unreadNotification'
    ]),

    async loadNotifications() {
      this.isLoading = true;
      await this.fetchNotifications({
        page: this.page,
        rowsPerPage: this.itemsPerPage,
        filters: this.buildFilters(),
        sorting: {
          creationDate: 'DESC'
        }
      });
      this.isLoading = false;
    },

    buildFilters() {
      let filters = {};
      if (this.statusSelection.length > 0) {
        filters.status = this.statusSelection;
      }

      if (this.dateSelection.length > 0) {
        filters.creationDate = {
          start: this.dateSelection[0].start / 1000 ?? null,
          end: this.dateSelection[0].end / 1000 ?? null
        };
      }

      return Object.keys(filters).length > 0 ? filters : undefined;
    },

    isUnread(entry) {
      return entry.status !== ReadStatus.READ;
    },

    scrollTo(target) {
      this.$vuetify.goTo(target, {
        duration: 500,
        offset: 0,
        easing: 'easeInOutCubic',
        container: '.v-dialog__content .scroll-overflow'
      });
    },

    async toggleRead(entry) {
      this.loadingEntriesIds.push(entry.id);

      if (this.isUnread(entry)) {
        await this.readNotification(entry.id);
      } else {
        await this.unreadNotification(entry.id);
      }

      await this.loadNotifications();

      this.loadingEntriesIds = this.loadingEntriesIds.filter((el) => el !== entry.id);
    },

    async onReadMultiple() {
      this.isLoading = true;
      this.isLoadingReadMultiple = true;
      this.loadingEntriesIds = [...this.selectedEntriesIds];

      await this.readMultipleNotifications(this.selectedEntriesIds);
      await this.loadNotifications();

      showSnackbar({
        text: 'notifications.selectedNotificationsWereRead',
        color: 'success',
        icon: '$iconCheck'
      });

      this.isLoading = false;
      this.isLoadingReadMultiple = false;
      this.loadingEntriesIds = [];
      this.selectedEntriesIds = [];
      this.showSelection = false;
    },

    async onReadAll() {
      this.isLoadingReadAll = true;
      this.loadingEntriesIds = this.notifications
        .filter((el) => this.isUnread(el))
        .map((el) => el.id);

      await this.readAllNotifications();
      await this.loadNotifications();

      this.loadingEntriesIds = [];
      this.isLoadingReadAll = false;
    },

    toggleShowSelection() {
      if (this.showSelection) {
        this.onCancelSelect();
        return;
      }
      this.showSelection = true;
    },

    toggleSelect(entryId) {
      this.selectedEntriesIds = this.selectedEntriesIds.includes(entryId)
        ? this.selectedEntriesIds.filter((id) => id !== entryId)
        : [...this.selectedEntriesIds, entryId];
    },

    onSelectAll() {
      this.notifications
        .filter((entry) => this.isUnread(entry))
        .forEach(
          (entry) =>
            !this.selectedEntriesIds.includes(entry.id) && this.selectedEntriesIds.push(entry.id)
        );
    },

    onCancelSelect() {
      this.showSelection = false;
      this.selectedEntriesIds = [];
    }
  }
};
</script>

<style scoped lang="scss">
.notifications__container {
  margin: 0 -24px;
  display: flex;
  flex-direction: column;
}

::v-deep .notifications__content--inset {
  padding-right: 24px;
  padding-left: 24px;
}

.notifications__pagination {
  width: 100%;
  padding: 16px 0 16px 0;
}

.notifications__btn {
  font-weight: var(--font-weight-semi-bold);
  letter-spacing: var(--button-letter-spacing);
  margin-block: 12px;
}
</style>
