<template>
  <app-dialog
    primary-action-text="navigation.confirmAndContinue"
    title="upload.confirmFactoringRules.title"
    v-bind="$attrs"
    @submit="$emit('confirm')"
    v-on="$listeners"
  >
    <p>
      {{ $t('upload.confirmFactoringRules.text') }}
    </p>

    <file-link-btn
      :href="product.documents.upload.rules"
      class="factoring-rules__button"
      prepend-icon
      icon-size="20"
      title="upload.confirmFactoringRules.factoringRules"
    />
  </app-dialog>
</template>

<script>
import FileLinkBtn from '@/shared/components/FileLinkBtn';

export default {
  name: 'ConfirmFactoringRulesDialog',

  components: { FileLinkBtn }
};
</script>

<style scoped lang="scss">
.factoring-rules__button.v-btn {
  margin-top: 16px;
  max-width: fit-content;

  ::v-deep .v-btn__content {
    display: inline-flex;
  }
}

.factoring-rules__icon {
  margin-right: 8px;
}
</style>
