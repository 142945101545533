<template>
  <v-container class="px-0">
    <app-input-row dense label="debtor">
      <disabled-input-box :value="location.name" />
    </app-input-row>

    <app-input-row dense label="permanentDebts.repetition">
      <app-select
        v-model="contractData.period"
        :items="periodSelectItems"
        :label="$t('permanentDebts.repetition')"
        required
        solo
      />
    </app-input-row>

    <app-input-row dense>
      <template #label>
        <div class="d-flex align-center">
          {{ $t('permanentDebts.startsOn') }}:
          <app-tooltip>
            <template #text>
              {{ minStartInfo }}
            </template>
            <icon-info class="ml-2 grey--text" height="15px" width="15px" />
          </app-tooltip>
        </div>
      </template>
      <app-date-picker
        v-model="contractData.startDate"
        :min="minStart"
        :rules="`min_date:${minStart}`"
        name="permanentDebts.startsOn"
        placeholder="dateHint"
        prepend-inner-icon
        align-right
        required
      />
    </app-input-row>

    <app-input-row dense label="permanentDebts.endsOn" sub-label="optional">
      <app-date-picker
        v-model="contractData.endDate"
        :min="minEnd"
        :rules="`min_date:${minEnd}`"
        name="permanentDebts.endsOn"
        placeholder="dateHint"
        prepend-inner-icon
        align-right
      />
    </app-input-row>

    <app-input-row v-if="showPerformanceDaySelect" dense label="permanentDebts.dayOfPerformance">
      <app-select
        v-model="contractData.dayOfPerformance"
        :items="performanceDaySelectItems"
        :label="$t('permanentDebts.dayOfPerformance')"
        required
        solo
      />
    </app-input-row>

    <app-input-row label="permanentDebts.firstExecution" dense>
      <disabled-input-box :value="firstExecutionDate" />
    </app-input-row>
  </v-container>
</template>

<script>
const DAYS_UNTIL_MIN_DATE = 5;

import { MILLISECONDS_PER_DAY } from '@/statics/timePeriods';
import {
  TimePeriod,
  PERIODS_REQUIRING_PERFORMANCE_DAY
} from '@/modules/PermanentDebts/statics/timePeriod';
import {
  formatTimestampToDate,
  formatDateToTimestamp,
  formatIsoToLocal
} from '@/helper/filter/formatDate';
import DisabledInputBox from '@/shared/components/DisabledInputBox';

export default {
  name: 'ContractForm',

  components: { DisabledInputBox },

  props: {
    formData: {
      type: Object,
      required: true
    },
    location: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      contractData: {
        ...this.formData.contract
      }
    };
  },

  computed: {
    // returns iso formatted date; first allowed date is DAYS_UNTIL_MIN_DATE days from today 0:00
    minStart() {
      return formatTimestampToDate(
        (new Date().setHours(0, 0, 0) + MILLISECONDS_PER_DAY * DAYS_UNTIL_MIN_DATE) / 1000
      );
    },
    minStartInfo() {
      return this.$t('permanentDebts.minStartInfo', { date: formatIsoToLocal(this.minStart) });
    },
    // one day past selected start date
    minEnd() {
      return formatTimestampToDate(
        (formatDateToTimestamp(this.contractData.startDate || this.minStart) * 1000 +
          MILLISECONDS_PER_DAY) /
          1000
      );
    },

    firstExecutionDate() {
      return this.contractData.startDate ? formatIsoToLocal(this.contractData.startDate) : '-';
    },
    periodSelectItems() {
      return this.filterItemsWithFulfilledCondition(
        Object.keys(this.$t('permanentDebts.periodOptions')).map((option) => ({
          label: this.$t(`permanentDebts.periodOptions.${option}`),
          value: option,
          condition: option === TimePeriod.DAILY ? this.isAdmin : true
        }))
      );
    },
    showPerformanceDaySelect() {
      return PERIODS_REQUIRING_PERFORMANCE_DAY.includes(this.contractData.period);
    },
    performanceDaySelectItems() {
      return Array.from(Array(31), (_, index) => index + 1).map((day) => ({
        label: day,
        value: day
      }));
    }
  },

  watch: {
    contractData: {
      handler(value) {
        this.$emit('update:form-data', {
          ...this.formData,
          contract: value
        });
      },
      deep: true
    }
  }
};
</script>
