<template>
  <div>
    <div v-if="!dropzoneEmpty" class="d-flex flex-column align-center justify-center mb-12">
      <div class="icon__wrapper">
        <icon-vouchers width="36px" height="36px" />
      </div>
      <h3 class="dropzone-custom-title semi-bold mt-4">{{ $t('upload.yourInvoices') }}</h3>
    </div>
    <app-dropzone
      v-model="vouchers"
      :additional-form-data="additionalFormData"
      :has-empty-queue.sync="dropzoneEmpty"
      :url="url"
      :start-upload="isUploading"
      :max-files="null"
      @success="finishUpload"
      @error="stopUpload"
    />
  </div>
</template>

<script>
import { BASE_URL } from '@/plugins/axios';
import VoucherUploadMixin from '@/mixins/VoucherUploadMixin';

export default {
  name: 'InvoiceUpload',

  mixins: [VoucherUploadMixin],

  computed: {
    url() {
      return `${BASE_URL}/voucher/upload/invoice`;
    }
  }
};
</script>
