<template>
  <v-container class="pa-0">
    <v-row class="mx-6" justify="center">
      <v-col class="shrink">
        <icon-clients />
      </v-col>
      <v-col class="grow">
        <div>{{ data.debtor.name }}</div>
        <div class="d-inline-flex">
          <p>
            <template v-if="data.debtor.address.streetAndHouse">
              {{ data.debtor.address.streetAndHouse }},&#32;
            </template>
            {{ data.debtor.address.zip }} {{ data.debtor.address.city }}
          </p>
        </div>

        <div v-if="isFactoring" class="mt-4">
          <span class="semi-bold">{{ $t('debtors.limit') }}:</span>
          {{ data.debtor.availableLimit | formatCurrency }}
          ({{ data.debtor.approvedLimit | formatCurrency }})
        </div>
      </v-col>
    </v-row>

    <div class="mt-9">{{ $t('debtors.addLocationRequest') }}</div>

    <v-card outlined class="mt-4">
      <v-card-title class="text-h5 font-weight-regular primary--text">
        {{ $t('debtors.addNewLocation') }}
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col class="shrink">
            <icon-map-marker />
          </v-col>
          <v-col class="grow">
            <p>{{ data.requestData.name }}</p>
            <div class="d-inline-flex">
              <p>
                <template v-if="data.requestData.streetAndHouse">
                  {{ data.requestData.streetAndHouse }},
                </template>
                {{ data.requestData.zip }} {{ data.requestData.city }}
              </p>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  name: 'ConfirmCreateLocation',

  props: {
    data: {
      type: Object,
      required: true
    }
  },

  created() {
    this.$emit('update:saveFunc', () => this.$emit('submit'));
  }
};
</script>

<style scoped>
#app.v-application .v-card.v-card--outlined {
  border-radius: 2px;
  border-color: var(--c-primary);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.22);
}

svg {
  margin-top: 6px;
}
</style>
